// import { Sale } from "./SaleMain.styles";
// import searchIcon from '../../../assets/images/searchicon.png'
import React, { useEffect, useRef, useState } from "react";
// import { User } from "../userMan/UserMan.styles";
import { addSale, changePass, deleteSale, getListUser, getSales, lockedSale, lockeduser, updateSale } from "../../../services/userManServices";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ChangePage } from "../../../store/manager/usermanagerSlice";
import { changePageSale, searchSale } from "../../../store/manager/saleSlice";
import { Pagination, Table, Dropdown, Modal, Button, Label, TextInput, Badge } from "flowbite-react";
import { FaFileAlt, FaLifeRing, FaLock, FaLockOpen, FaPen } from "react-icons/fa";
import Header from "../../../components/header/Header";
import { HiOutlineArrowRight } from 'react-icons/hi';
const SaleMain = () => {
    const [users, setUsers] = useState([]);
    const [value, setValue] = useState('')
    const [page, setPage] = useState(1);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [editItem, setEditItem] = useState()
    const [deleteItem, setDeleteItem] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const inputNameRef = useRef();
    const inputPassRef = useRef();

    const params = useSelector(state => state.sale)
    useEffect(() => {
        handleGetSale(params)
    }, [page, params])


    const handleGetSale = (params) => {
        getSales(params)
            .then(res => {
                setUsers(res.data);
                setPageCount(res.data.meta.pageCount);
                setPage(res.data.meta.page);
                Notiflix.Loading.remove();
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeleteSale = (id) => {
        deleteSale(id)
            .then(res => {
                Notiflix.Notify.success('Xoá thành công')
                setOpenDeleteModal(!openDeleteModal)
                handleGetSale(params)
            })
            .catch(err => {
                Notiflix.Notify.failure('Xoá không thành công')
            })
    }

    const handleSearch = (e) => {
        setValue(e.target.value)
    }

    const handleLock = ({ isLocked, id }) => {
        lockedSale({ idSale: id, isLocked: !isLocked })
            .then(() => {
                Notiflix.Notify.success('Cập nhật thành công !')
                handleGetSale(params)
            })
    }

    const handleBtnSearch = () => {
        dispatch(searchSale(value))
        setPage(1)
    }

    const handleChangePage = (e) => {
        dispatch(changePageSale(e))
        setPage(e)
    }

    const editModal = (item) => {
        setOpenEditModal(!openEditModal)
        setEditItem(item);
    }
    const deleteModal = (item) => {
        setOpenDeleteModal(!openDeleteModal)
        setDeleteItem(item);
    }
    const addModal = () => {
        setOpenAddModal(!openAddModal)
    }

    const onChangeEdit = (e) => { }

    const onSubmit = () => {
        const name = inputNameRef.current.value;
        const pass = inputPassRef.current.value;
        updateSale({
            id: editItem.id,
            username: name,
            password: pass,
        })
            .then(res => {
                Notiflix.Notify.success('Cập nhật thành công')
                setOpenEditModal(!openEditModal)
                handleGetSale(params)
            })
            .catch(err => {
                Notiflix.Notify.failure('Cập nhật thất bại')
            })
        var editForm = { ...editItem, username: name, password: pass };
    };

    const handledAddSale = async () => {
        const name = inputNameRef.current.value;
        const pass = inputPassRef.current.value;
        await addSale({
            username: name,
            password: pass,
        })
            .then(res => {
                Notiflix.Notify.success('Thêm sale thành công')
                setOpenAddModal(!openAddModal)
                handleGetSale(params)
            })
            .catch(err => {
                console.log(err);
                console.log(err.response.data.code);
            })
    }

    return (
        <div>

            {openEditModal === true ? <Modal
                onClose={editModal}
                show={openEditModal}
            >
                <Modal.Header>
                    Sửa Đại Lý
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full">
                        <div className="flex w-full flex-col gap-4">
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="name"
                                        value="Tên Đại Lý"
                                    />
                                </div>
                                <TextInput
                                    id="name"
                                    sizing="sm"
                                    ref={inputNameRef}
                                    type="text"
                                    name="username"
                                    onChange={onChangeEdit}
                                    defaultValue={editItem.username}
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="name"
                                        value="Mật Khẩu"
                                    />
                                </div>
                                <TextInput
                                    id="password"
                                    sizing="sm"
                                    type="text"
                                    ref={inputPassRef}
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="name"
                                        value="Mã Đại Lý"
                                    />
                                </div>
                                <TextInput
                                    id="name"
                                    sizing="sm"
                                    type="text"
                                    value={editItem.code}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={onSubmit}
                    >
                        Chỉnh Sửa
                    </Button>
                    <Button
                        color="gray"
                        onClick={editModal}
                    >
                        <p>
                            Huỷ Bỏ
                        </p>
                    </Button>
                </Modal.Footer>
            </Modal> : ""}
            {openDeleteModal === true ? <Modal
                onClose={deleteModal}
                show={openDeleteModal}
                popup
                size="md"
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <FaLifeRing className="mx-auto mb-5 h-10 w-10 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            <p>
                                Bạn có chắc chắn muốn xoá đại lý <span className="text-red-600">{deleteItem.username}</span> không ?
                            </p>
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button
                                color="failure"
                                onClick={() => handleDeleteSale(deleteItem.id)}
                            >
                                Có, Chắc Chắn
                            </Button>
                            <Button
                                color="gray"
                                onClick={deleteModal}
                            >
                                <p>
                                    Không, Huỷ Bỏ
                                </p>
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> : ""}
            {openAddModal === true ? <Modal
                onClose={addModal}
                show={openAddModal}
            >
                <Modal.Header>
                    Thêm Đại Lý
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full">
                        <div className="flex w-full flex-col gap-4">
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="name"
                                        value="Tên Đại Lý"
                                    />
                                </div>
                                <TextInput
                                    id="name"
                                    sizing="sm"
                                    ref={inputNameRef}
                                    type="text"
                                    name="username"
                                    onChange={onChangeEdit}
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="name"
                                        value="Mật Khẩu"
                                    />
                                </div>
                                <TextInput
                                    id="password"
                                    onChange={onChangeEdit}
                                    sizing="sm"
                                    type="password"
                                    ref={inputPassRef}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handledAddSale}
                    >
                        Tạo Mới
                    </Button>
                    <Button
                        color="gray"
                        onClick={addModal}
                    >
                        <p>
                            Huỷ Bỏ
                        </p>
                    </Button>
                </Modal.Footer>
            </Modal> : ""}
            <Header />
            <Table hoverable striped className='drop-shadow-none'>
                <Table.Head>
                    <Table.HeadCell>
                        ID
                    </Table.HeadCell>
                    <Table.HeadCell>
                        TÊN
                    </Table.HeadCell>
                    <Table.HeadCell>
                        MÃ ĐẠI LÝ
                    </Table.HeadCell>
                    <Table.HeadCell>
                        TRẠNG THÁI
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Hành động
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <Button gradientDuoTone="purpleToBlue" outline onClick={addModal}> Thêm Sale <HiOutlineArrowRight className="ml-2 h-5 w-5" /></Button>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {users.data?.map((item, index) => (
                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell>
                                {item.id}
                            </Table.Cell>
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {item.username}
                            </Table.Cell>
                            <Table.Cell>
                                {item.code}
                            </Table.Cell>
                            <Table.Cell>
                                {item.isLocked === true ? <Badge color="failure" className="w-[150px] justify-center" size="sm" >Khoá - {item.role}</Badge> : <Badge className="w-[150px] justify-center" size="sm" color="success">Hoạt động  - {item.role}</Badge>}
                            </Table.Cell>
                            <Table.Cell>
                                <Dropdown label="Hành Động">
                                    <Dropdown.Item icon={FaPen} onClick={() => editModal(item)}>
                                        Sửa
                                    </Dropdown.Item>
                                    <Dropdown.Item icon={FaFileAlt} onClick={() => deleteModal(item)}>
                                        Xoá
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item icon={FaLock} onClick={() => handleLock(item)}>
                                        Khoá / Mở Khoá
                                    </Dropdown.Item>
                                </Dropdown>
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination className="flex mt-5 items-center justify-center text-center flex-col"
                currentPage={page}
                onPageChange={handleChangePage}
                showIcons
                totalPages={pageCount}
            />
        </div>
    );
}

export default SaleMain;
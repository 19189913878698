import React, { useEffect } from 'react'
import { format } from 'date-fns'
import './detailuser.css';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ChangePassWord, deleteTransaction, deposit, getDetailUser, historyTrading, historyTran, imgCard, lockeduser, withdraw } from '../../services/userManServices'
import { getToken } from '../../utils/localStorage'
import { useForm } from "react-hook-form";
import Notiflix, { Loading } from 'notiflix'
import Header from '../header/Header'
import { Avatar, Badge, Button, Card, Carousel, Checkbox, Label, Spinner, Table, TextInput } from 'flowbite-react'
import ReactApexChart from 'react-apexcharts'
import { FaAngleDoubleDown, FaInfoCircle, FaRegCreditCard } from 'react-icons/fa'
import Datepicker from "tailwind-datepicker-react"
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";

const optionsDate = {
    title: "Ngày Giao Dịch",
    autoHide: true,
    todayBtn: false,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2000 -01-01"),
    theme: {
        background: "bg-white dark:bg-gray-800",
        todayBtn: "",
        clearBtn: "",
        icons: "",
        text: "",
        disabledText: "",
        input: "",
        inputIcon: "",
        selected: "",
    },
    icons: {
        prev: () => <span><IoArrowBackSharp /></span>,
        next: () => <span><IoArrowForwardSharp /></span>,
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date(),
    language: "vn",
}

function DetailUser() {
    const [value, setValue] = React.useState(format(new Date(), 'yyyy-MM-dd'))
    const [user, setUser] = React.useState();
    const [history, setHistory] = React.useState();
    const [history2, setHistory2] = React.useState();
    const [cardback, setCardback] = React.useState();
    const [cardfront, setCardfront] = React.useState();
    const [cardavt, setCardavt] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [drawer, setDrawer] = React.useState(false);
    const [drawer2, setDrawer2] = React.useState(false);
    const [money, setMoney] = React.useState('');
    const [typeModal, setTypeModal] = React.useState('');
    const [newpass, setNewpass] = React.useState('')
    const [sumDeposit, setSumDeposit] = React.useState('')
    const [sumWithdrawl, setSumWithdrawl] = React.useState('')
    const admin = useSelector((state) => state.auth.user);
    const [series, setSeries] = React.useState([0, 0])
    const [show, setShow] = React.useState(false)
    const handleChange = (selectedDate) => {
        setValue(format(selectedDate, 'yyyy-MM-dd'));
        historyTrading({ page: page, email: mailUser ? mailUser : mailUserLocal, date: format(selectedDate, 'yyyy-MM-dd') })
            .then(res => {
                setHistory(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }
    const handleClose = (state) => {
        setShow(state)
    }
    const [options, setOpions] = React.useState({
        chart: {
            width: 280,
            type: 'pie',
        },
        labels: ["Nạp", "Rút"],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 150
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    });
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // get detail user

    const mailUser = useSelector(state => state.detailUser.email);
    const mailUserLocal = localStorage.getItem('emailUser')
    const getUser = React.useCallback(async () => {
        await getDetailUser(mailUser ? mailUser : mailUserLocal)
            .then(res => {
                setUser(res.data);
                    //   imgCard({ filename: res.data.cardImage, token: getToken() })
                    // .then(res => {
                    //     setCardavt(res.request.responseURL);
                    // })
                    // .catch(err => {
                    //     console.log(err);
                    // })
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    useEffect(() => {
        getUser()
    }, [getUser])

    const InfoUser = (item) => {
        if (item && item !== null) {
            return (
                <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="flex flex-col pb-1">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">Email</dt>
                        <dd className="text-base font-semibold">{user?.email}</dd>
                    </div>
                    <div className="flex flex-col py-1">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">Họ và tên</dt>
                        <dd className="text-base font-semibold">{user?.fullName}</dd>
                    </div>
                    <div className="flex flex-col pt-1">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">Số điện thoại</dt>
                        <dd className="text-base font-semibold">{user?.phone}</dd>
                    </div>
                    <div className="flex flex-col pt-1">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">Trạng Thái</dt>
                        <dd className="text-base font-semibold">{user?.isLocked === true ? <Badge className='justify-center' color='failure'>Khoá</Badge> : <Badge color='success' className='justify-center'>Hoạt Động</Badge>}</dd>
                    </div>
                </dl>
            )
        } else {
            return (
                <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[400px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[480px]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[440px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2 max-w-[360px]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>
            )
        }
    }

    // get lịch trading của user
    useEffect(() => {
        getHistoryTrading()
    }, [])

    const getHistoryTrading = () => {
        historyTrading({ page: page, email: mailUser ? mailUser : mailUserLocal, date: value })
            .then(res => {
                setHistory(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const deleteTrans = (id) => {
        deleteTransaction(id)
            .then(res => {
                getHistory();
            })
            .catch(err => {
                console.log(err);
            })
    }

    // get lịch sử nạp rút của user
    const getHistory = () => {
        historyTran({ page: page, email: mailUser ? mailUser : mailUserLocal })
            .then(res => {
                const data = (res.data.data);
                setHistory2(data);
                console.log(history2);
                if (data) {
                    var nap = 0;
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].type == 'DEPOSIT') {
                            nap = Number(nap) + Number(data[i].amount);
                        }
                    }
                    var rut = 0;
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].type == 'WITHDRAW') {
                            rut = Number(rut) + Number(data[i].amount);
                        }
                    }
                    setSeries([nap, rut])
                    setSumDeposit(rut - nap);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        getHistory()
    }, [])

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = data => {
        // Notiflix.Loading.pulse();
        if (typeModal == 'rut') {
            withdraw({ amount: money, email: mailUser ? mailUser : mailUserLocal })
                .then(res => {
                    Notiflix.Notify.success('tạo lệnh rút tiền thành công')
                    historyTran({ page: page, email: mailUser ? mailUser : mailUserLocal })
                        .then(res => {
                            setMoney('');
                            getUser()
                            setHistory2(res.data.data);
                            getHistory()
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    setDrawer(false);
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.data.code = "NOT_ENOUGH_MONEY") {
                        Notiflix.Notify.failure('không đủ tiền')
                    } else {
                        Notiflix.Notify.failure('không đủ tiền')
                    }
                })
            // Notiflix.Loading.remove();
        } else {
            deposit({ amount: money, email: mailUser ? mailUser : mailUserLocal })
                .then(res => {
                    Notiflix.Notify.success('tạo lệnh nạp tiền thành công')
                    setDrawer(false);
                    historyTran({ page: page, email: mailUser ? mailUser : mailUserLocal })
                        .then(res => {
                            getUser()
                            setHistory2(res.data.data);
                            getHistory()
                        })
                        .catch(err => {
                            console.log(err);
                        })
                })
                .catch(err => {
                    console.log(err);
                    Notiflix.Notify.failure('nạp tiền không thành công')
                })
            Notiflix.Loading.remove();
        }

    }
    // handle lock user
    const handleLock = (value) => {
        lockeduser({ email: user.email, isLocked: value })
            .then(() => {
                getUser()
                Notiflix.Notify.success('Cập nhật thành công !')
            })
    }

    // check type mua ban huy
    const checkType = (type) => {
        switch (type) {
            case 'BUY':
                return 'Mua'
            case 'SELL':
                return 'Bán'
            default:
                return 'Hủy'
        }
    }
    // form change pass user
    const changePass = data => {
        // console.log(newpass);
        ChangePassWord({
            "email": user?.email,
            "password": newpass
        })
            .then(res => {
                Notiflix.Notify.success('đổi mật khẩu thành công')
                setNewpass('')
                setDrawer2(false);
            })
            .catch(err => {
                console.log(err);
                if (err.response.data.message[0] === "password must be longer than or equal to 3 characters") {
                    Notiflix.Notify.failure('Mật khẩu tối thiểu 3 ký tự')
                } else if (err.response.data.message[0] === "password must be shorter than or equal to 20 characters") {
                    Notiflix.Notify.failure('Mật khẩu tối đa 20 ký tự')
                }
                else {
                    Notiflix.Notify.failure('Đổi mật khẩu không thành công')
                }
            })
    };
    const loadAnh = (user) => {
        console.log(user);
        imgCard({ filename: user.cardImage, token: getToken() })
        .then(res => {
            setCardavt(res.request.responseURL);
        })
        .catch(err => {
            console.log(err);
        })
    imgCard({ filename: user.cardFrontImage, token: getToken() })
        .then(res => {
            setCardfront(res.request.responseURL);
        })
        .catch(err => {
            console.log(err);
        })
    imgCard({ filename: user.cardBackImage, token: getToken() })
        .then(res => {
            setCardback(res.request.responseURL);
        })
        .catch(err => {
            console.log(err);
        })
    }
    const userAdmin = useSelector((state) => state.auth.user);

    if (!user && !history2) return "Loading";
    return (
        <div>
            <Header />
            <div className="grid grid-cols-3 gap-2 px-2">
                <Card className='flex items-start w-full customCard'>
                    <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                        THÔNG TIN TÀI KHOẢN
                    </h5>
                    <div className=" flex w-full justify-left space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
                        {cardavt ? <Avatar img={cardavt} rounded size="xl" /> : 
                        <div role="status" className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center">
                            Bấm tải ảnh để xem ảnh
                        </div>
                            
                        }
                        
                        <InfoUser item={user} />
                    </div>
                    {userAdmin.role === "ADMIN" ? <Button pill color={user?.isLocked === true ? "success" : "failure"} className='w-full' onClick={() => handleLock(!user?.isLocked)}>{user?.isLocked === true ? "MỞ KHOÁ TÀI KHOẢN" : "KHOÁ TÀI KHOẢN"}</Button> : ""}
                </Card>
                <Card className='customCard'>
                    <Button onClick={() => loadAnh(user)}>Tải ảnh</Button>
                    {cardavt && cardfront && cardback ?
                        <Carousel>
                            <img
                                alt="avt"
                                src={cardavt}
                            />
                            <img
                                alt="cccd1"
                                src={cardfront}
                            />
                            <img
                                alt="cccd2"
                                src={cardback}
                            />
                        </Carousel>
                        : "Vui lòng bấm tải ảnh để xem ảnh"}
                </Card>
                <Card className='customCard'>
                    <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                        NẠP / RÚT
                    </h5>
                    <div className="items-center justify-center space-y-4 sm:flex sm:space-x-4 sm:space-y-0 flex flex-row">
                        <div id="chart">
                            <h5 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                                SỐ DƯ: {user?.money}$<br />
                                LỢI NHUẬN: {sumDeposit}.00$
                            </h5>
                            <ReactApexChart options={options} series={series} type="pie" width={280} />
                            {userAdmin.role === "ADMIN" ? 
                            <div className='flex mt-5 justify-between'>
                                <Button color="success" pill onClick={() => { setDrawer(true); setTypeModal("nap") }}><FaRegCreditCard className='mr-2' /> NẠP TIỀN</Button>
                                <Button color="failure" className='mx-2' pill onClick={() => { setDrawer(true); setTypeModal("rut") }}><FaAngleDoubleDown className='mr-2' /> RÚT TIỀN</Button>
                                <Button color="dark" pill onClick={() => setDrawer2(true)}><FaInfoCircle className='mr-2' /> ĐỔI MẬT KHẨU</Button>
                            </div>
                            : "" }
                        </div>
                    </div>
                </Card>
                <Card className='col-span-2 customCard2'>
                    <div className='grid grid-cols-4 gap-2'>
                        <h5 className="col-span-3 mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                            LỊCH SỬ GIAO DỊCH
                        </h5>
                        <div className='flex col-span-1'>
                            <Datepicker options={optionsDate} onChange={handleChange} show={show} setShow={handleClose} />
                        </div>
                    </div>
                    <Table hoverable>
                        <Table.Head>
                            <Table.HeadCell>
                                STT
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Ngày giao dịch
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Giao dịch
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Lợi Nhuận
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Đầu Tư
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {history ? history.data.map((item, index) => (
                                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {(5 * page + index + 1) - 5}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {format(new Date(item.createdAt), 'HH:mm - dd.MM.yyy')}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.status == 'CANCEL' ? 'Huy' : checkType(item.position)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.status === 'LOSE' ? <Badge color="failure">- ${item.profit}</Badge> : <Badge color="success">+ ${item.profit}</Badge>}
                                    </Table.Cell>
                                    <Table.Cell>
                                        ${item.amount}
                                    </Table.Cell>
                                </Table.Row>
                            )) :
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800"><Table.Cell colSpan={5}>Không Có Dữ Liệu</Table.Cell></Table.Row>
                            }

                        </Table.Body>
                    </Table>
                </Card>
                <Card className='col-span-1 customCard2'>
                    <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                        LỊCH SỬ NẠP / RÚT
                    </h5>
                    <Table hoverable>
                        <Table.Head>
                            <Table.HeadCell>
                                NGÀY GIAO DỊCH
                            </Table.HeadCell>
                            <Table.HeadCell>
                                SỐ TIỀN
                            </Table.HeadCell>
                            <Table.HeadCell>
                                XOÁ
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {history2 ?
                                history2.map((item, index) => (
                                    <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            {format(new Date(item.createdAt), 'HH:mm - dd.MM.yyy')}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.type == "DEPOSIT" ? <p className='text-green-600'>+ {item.amount}</p> : <p className='text-red-500'>- {item.amount}</p>}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button onClick={() => deleteTrans(item.id)}>Delete</Button>
                                        </Table.Cell>
                                    </Table.Row>
                                )) : <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800"><Table.Cell>Không Có Dữ Liệu</Table.Cell></Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </Card>
            </div>

            <div id="drawer-contact" style={{ transition: "0.5s ease all", right: drawer === true ? "0%" : "-100%" }} className="fixed shadow-2xl top-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white w-[500px] dark:bg-gray-800" tabIndex="-1" aria-labelledby="drawer-contact-label">
                <h5 id="drawer-label" className="inline-flex items-center mb-6 text-base font-semibold text-gray-500 uppercase dark:text-gray-400"><svg className="w-5 h-5 mr-2" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>{typeModal === 'nap' ? "Nạp Tiền" : "Rút Tiền"}</h5>
                <button onClick={() => setDrawer(false)} type="button" data-drawer-hide="drawer-contact" aria-controls="drawer-contact" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" >
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close menu</span>
                </button>
                <form className="mb-6">
                    <div className="mb-6">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{typeModal === 'nap' ? "Tài Khoản Nạp" : "Tài Khoản Rút"}</label>
                        <input type="email" id="email" defaultValue={user?.email} readOnly className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="money" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{typeModal === 'nap' ? "Số Tiền Nạp" : "Số Tiền Rút"}</label>
                        <input type="text" value={money} onChange={e => setMoney(e.target.value)} id="money" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Vui lòng nhập số tiền" required />
                    </div>
                    <div className='flex justify-between mb-2'>
                        <Button gradientDuoTone="cyanToBlue" onClick={() => setMoney(50)} outline>
                            50$
                        </Button>
                        <Button gradientDuoTone="cyanToBlue" onClick={() => setMoney(100)} outline>
                            100$
                        </Button>
                        <Button gradientDuoTone="cyanToBlue" onClick={() => setMoney(200)} outline>
                            200$
                        </Button>
                        <Button gradientDuoTone="cyanToBlue" onClick={() => setMoney(500)} outline>
                            500$
                        </Button>
                        <Button gradientDuoTone="cyanToBlue" onClick={() => setMoney(1000)} outline>
                            1000$
                        </Button>
                        <Button gradientDuoTone="cyanToBlue" onClick={() => setMoney(2000)} outline>
                            2000$
                        </Button>
                    </div>
                    <button type="button" onClick={onSubmit} className="text-white bg-blue-700 hover:bg-blue-800 w-full focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 block">{typeModal === 'nap' ? "Nạp Tiền" : "Rút Tiền"}</button>

                </form>
            </div>
            <div id="chang-password" style={{ transition: "0.5s ease all", right: drawer2 === true ? "0%" : "-100%" }} className="fixed shadow-2xl top-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white w-[500px] dark:bg-gray-800" tabIndex="-1" aria-labelledby="drawer-contact-label">
                <h5 id="drawer-label" className="inline-flex items-center mb-6 text-base font-semibold text-gray-500 uppercase dark:text-gray-400"><svg className="w-5 h-5 mr-2" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>ĐỔI MẬT KHẨU</h5>
                <button onClick={() => setDrawer2(false)} type="button" data-drawer-hide="drawer-contact" aria-controls="drawer-contact" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" >
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close menu</span>
                </button>
                <form className="mb-6">
                    <div className="mb-6">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tài khoản</label>
                        <input type="email" id="email" defaultValue={user?.email} readOnly className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="passw" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mật khẩu mới</label>
                        <input type="text" value={newpass} onChange={e => setNewpass(e.target.value)} id="passw" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Vui lòng nhập mật khẩu mới" required />
                    </div>
                    <button type="button" onClick={changePass} className="text-white bg-blue-700 hover:bg-blue-800 w-full focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 block">Đổi Mật Khẩu</button>
                </form>
            </div>

        </div>
    )
}

export default DetailUser
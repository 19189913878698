import Login from "../screens/login/Login";
import { Routes, Route } from 'react-router-dom';

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
        </Routes>
    )
}

export default PublicRoutes;
import React, { useEffect, useRef, useState } from 'react'
// import { Accounts } from './Account.styles'
import camera from '../../../assets/images/camera.png'
import avt from '../../../assets/images/avt.png'
import { useForm } from "react-hook-form";
import { account, changePass } from '../../../services/userManServices';
import Notiflix from 'notiflix';
import Header from "../../../components/header/Header";
import { Button, Card, Dropdown, Label, Modal, TextInput } from 'flowbite-react';

function Account() {
    const [info, setInfo] = useState('');
    const [name, setName] = useState('');
    // const [passWord, setPassword] = useState('');
    // const [rePassword, setRepassword] = useState('');
    const inputPass1Ref = useRef();
    const inputPass2Ref = useRef();
    const [changePassModal, setchangePassModal] = useState(false);

    // config react form
    const { register, getValues, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        const pass = inputPass2Ref.current.value;
        changePass({
            username: name,
            password: pass
        })
            .then(res => {
                Notiflix.Notify.success('Cập nhật thành công')
                handleChangePass();
            })
            .catch(err => {
                Notiflix.Notify.failure('Cập nhật thất bại')
            })
    };
    // set acount
    useEffect(() => {
        account()
            .then(res => {
                setName(res.data.username);
                setInfo(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    const onChangeEdit = (e) => { }

    const handleChangePass = () => {
        setchangePassModal(!changePassModal)
        // setEditItem(item);
    }
    return (
        <div>
            {changePassModal === true ? <Modal
                onClose={handleChangePass}
                show={changePassModal}
            >
                <Modal.Header>
                    Cập nhật mật khẩu
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full">
                        <div className="flex w-full flex-col gap-4">
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="name"
                                        value="Mật khẩu mới"
                                    />
                                </div>
                                <TextInput
                                    id="pass1"
                                    sizing="sm"
                                    ref={inputPass1Ref}
                                    type="password"
                                    name="password1"
                                    onChange={onChangeEdit}
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="name"
                                        value="Nhập lại mật khẩu mới"
                                    />
                                </div>
                                <TextInput
                                    id="password"
                                    sizing="sm"
                                    type="password"
                                    ref={inputPass2Ref}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={onSubmit}
                    >
                        Chỉnh Sửa
                    </Button>
                    <Button
                        color="gray"
                        onClick={handleChangePass}
                    >
                        <p>
                            Huỷ Bỏ
                        </p>
                    </Button>
                </Modal.Footer>
            </Modal> : ""}
            <Header />
            {/* <Accounts>
                <Accounts.Header>
                    <span>Tài khoản</span>
                </Accounts.Header>
                <Accounts.Body onSubmit={handleSubmit(onSubmit)}>
                    <Accounts.Bodybg>
                        <Accounts.Boxavt>
                            <Accounts.avt src={avt} />
                            <Accounts.Boxcam>
                                <Accounts.Cam src={camera} />
                            </Accounts.Boxcam>
                        </Accounts.Boxavt>
                    </Accounts.Bodybg>
                    <Accounts.Boxdetail>
                        <div className='h-fit'>
                            <Accounts.ItemInfo>
                                <Accounts.ItemSpan>Tên:</Accounts.ItemSpan>
                                <Accounts.ItemInput type="text" value={name} onChange={e => setName(e.target.value)} />
                            </Accounts.ItemInfo>
                            <Accounts.ItemInfo>
                                <Accounts.ItemSpan>Password:</Accounts.ItemSpan>
                                <Accounts.ItemInput
                                    {...register("password", {
                                        required: true,
                                        minLength: 6,
                                    })}
                                    placeholder="nhập mật khẩu mới"
                                    value={passWord}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </Accounts.ItemInfo>
                            {errors?.password?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập mật khẩu </p>}
                            {errors?.password?.type === "minLength" && <p className="text-[#FF0000] text-[14px]">*Mật khẩu yêu cầu ít nhất 6 ký tự </p>}
                            <Accounts.ItemInfo>
                                <Accounts.ItemSpan>Re Password:</Accounts.ItemSpan>
                                <Accounts.ItemInput
                                    {...register("confirm_password", {
                                        required: true,
                                        validate: (value) => {
                                            const { password } = getValues();
                                            return password === value || "Passwords should match!";
                                        }
                                    })}
                                    placeholder="nhập lại mật khẩu mới"
                                    value={rePassword}
                                    onChange={e => setRepassword(e.target.value)}
                                />
                            </Accounts.ItemInfo>
                            {errors?.confirm_password?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng nhập lại mật khẩu </p>}
                            {errors?.confirm_password?.type === "validate" && <p className="text-[#FF0000] text-[14px]">*Mật khẩu không trùng khớp</p>}
                        </div>
                    </Accounts.Boxdetail>
                    <Accounts.Button>Cập nhật tài khoản</Accounts.Button>
                </Accounts.Body>
            </Accounts> */}
            <Card>
                <div className="flex justify-end px-4 pt-4">
                    <Dropdown
                        inline
                        label=""
                    >
                        <Dropdown.Item>
                            <a
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                                href="#"
                            >
                                <p>
                                    Edit
                                </p>
                            </a>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <a
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                                href="#"
                            >
                                <p>
                                    Export Data
                                </p>
                            </a>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <a
                                className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                                href="#"
                            >
                                <p>
                                    Delete
                                </p>
                            </a>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
                <div className="flex flex-col items-center pb-10">
                    <img
                        alt="Bonnie image"
                        className="mb-3 rounded-full shadow-lg"
                        height="96"
                        src={avt}
                        width="96"
                    />
                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                        {info.username}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                        {info.role}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                        Ngày gia nhập: {info.createdAt}
                    </span>
                    <div className="mt-4 flex space-x-3 lg:mt-6">
                        <a
                            className="inline-flex items-center rounded-lg bg-cyan-700 px-4 py-2 text-center text-sm font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
                            href="#"
                            onClick={handleChangePass}
                        >
                            <p>
                                Đổi Mật Khẩu
                            </p>
                        </a>
                        <a
                            className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-4 py-2 text-center text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                            href="#"
                        >
                            <p>
                                Message
                            </p>
                        </a>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default Account
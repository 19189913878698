import AxiosClient from "./axiosClient";

// ví dụ
const API_ENDPOINT = "manager/"

export const getListUser = (params) => {
    return AxiosClient.get(API_ENDPOINT + `users?order=DESC&page=${params.page}&take=${10}&search=${params.search}`)
}

export const getSales = (params) => {
    return AxiosClient.get(API_ENDPOINT + `sales` ,{
        params: {
            page: params.page,
            take: params.take,
            search: params.search,
            order: params.order
        }
    })
}

export const getDetailUser = (email) => {
    return AxiosClient.get(API_ENDPOINT + `one?email=${email}`)
}

export const deleteSale = (id) => {
    return AxiosClient.put(API_ENDPOINT + `sale/delete?id=${id}`)
}

export const updateSale = (value) => {
    return AxiosClient.put(API_ENDPOINT + `sale/update?id=${value.id}&username=${value.username}&password=${value.password}`)
}

export const imgCard = ({ filename, token }) => {
    return AxiosClient.get('user/image/' + filename + '&{token}?token=' + token)
}

export const historyTrading = (params) => {
    return AxiosClient.get(`trading/history/all?order=DESC&page=${params.page}&take=5&date=${params.date}&email=${params.email}`)
}
export const historyTran = (params) => {
    return AxiosClient.get(`transaction/history/all?order=DESC&email=${params.email}`)
}

export const lockeduser = (params) => {
    return AxiosClient.put(`manager/users/lock?email=${params.email}&isLocked=${params.isLocked}`)
}

export const lockedSale = (params) => {
    return AxiosClient.put(`manager/sale/lock?idSale=${params.idSale}&isLocked=${params.isLocked}`)
}

export const deleteTransaction = (id) => {
    return AxiosClient.put(`transaction/delete?id=${id}`)
}

export const withdraw = (data) => {
    return AxiosClient.post(`transaction/manager/withdraw`, data)
}

export const deposit = (data) => {
    return AxiosClient.post(`transaction/manager/deposit`, data)
}
export const account = () => {
    return AxiosClient.get(API_ENDPOINT + `account`)
}
export const changePass = ({username, password}) => {
    return AxiosClient.patch(API_ENDPOINT + `account?password=${password}&username=${username}`)
}

export const addSale =(data) =>{
    return AxiosClient.post(API_ENDPOINT + `sale`, data)
}
// lịch sử nạp 
export const historyNap = () => {
    return AxiosClient.get('transaction/manager/bank')
}
export const historyNapUpdate = (data) => {
    return AxiosClient.post('transaction/manager/bank', data)
}
export const getListDeposit = (param) => {
    if (param) {
        return AxiosClient.get(`transaction/manager/history-deposit?search=${param}`)
    } else {
        return AxiosClient.get(`transaction/manager/history-deposit`)
    }
}
// change password
export const ChangePassWord = (data) => {
    return AxiosClient.post('user/forgot-password-admin', data)
}
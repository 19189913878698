import AxiosClient from "./axiosClient";

const API_ENDPOINT = "manager/";

export const login = (data) => {
    return AxiosClient.post(API_ENDPOINT + 'login', data)
}

export const getAdmin = () => {
    return AxiosClient.get(API_ENDPOINT + 'account')
}
import React, { useEffect, useState } from 'react'
import { Loading } from 'notiflix';
import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoutes from './protectedRoutes';
import PublicRoutes from './publicRoutes';
import { getToken, setToken } from '../utils/localStorage';
import { getUserInfor } from '../store/auth/authThunk';
import { refeshToken } from '../services/refeshTokenServices';
import SaleRoutes from './SaleRoutes';

 function AppRoutes() {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    const admin = useSelector((state) => state.auth.user);
    // console.log(admin);
     let Routes = PublicRoutes;
    if (admin){
        if (admin.role === 'ADMIN'){
            Routes = ProtectedRoutes;
        }
        else if (admin.role === 'SALE'){
            Routes = SaleRoutes;
        }
    }


    // get user
    useEffect(() => {
        const jwtToken = getToken();

        if (jwtToken) {
            dispatch(getUserInfor());
        }
    }, []);
    // refesh token
    if (!admin && admin === {}) {
        refeshToken()
            .then(res => {
                setToken(res.data.accessToken)
            })
            .catch(err => {
                console.log(err);
            })
    }

    return !loading && <React.Fragment><Routes /></React.Fragment>;
}

export default AppRoutes
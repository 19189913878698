import React from 'react'
import { Head } from './Header.styles'
import menu from '../../assets/images/menu.png'
import { useDispatch } from 'react-redux'
import { changeIsOpen } from '../../store/navRes/navResSlice'
import { Navbar, Dropdown, Avatar } from 'flowbite-react';
import { useNavigate } from 'react-router-dom'
import { removeToken } from '../../utils/localStorage'
function Header({ title }) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const handleLogOut = () => {
        removeToken()
        navigate('/')
        window.location.reload();
    }
    return (
        // <Head>
        //     <Head.Text>{title}</Head.Text>
        //     <Head.Icon onClick={() => dispatch(changeIsOpen(true))} src={menu} />
        // </Head>
        <Navbar
            fluid
            rounded
        >
            <div className="flex md:order-2">
                <Dropdown
                    inline
                    label={<Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded />}
                >
                    <Dropdown.Header>
                        <span className="block text-sm">
                            Bonnie Green
                        </span>
                        <span className="block truncate text-sm font-medium">
                            name@flowbite.com
                        </span>
                    </Dropdown.Header>
                    <Dropdown.Item>
                        Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            navigate('/account')
                            dispatch(changeIsOpen(false))
                        }}>
                        Cài Đặt
                    </Dropdown.Item>
                    <Dropdown.Item>
                        Earnings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogOut}>
                        Đăng Xuất
                    </Dropdown.Item>
                </Dropdown>
                <Navbar.Toggle />
            </div>
            <Navbar.Collapse>
                <Navbar.Link
                    active
                    href="https://chat.onebidd.com"
                >
                    <p>
                        Chat OneBid
                    </p>
                </Navbar.Link>

            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header
import { configureStore } from '@reduxjs/toolkit'
import navResReducer from './navRes/navResSlice'
import authReducer from './auth/authSlice'
import userManagerReducer from './manager/usermanagerSlice'
import detailUserReducer from './detailUser/detailUserSlice'
import salesReducer from './manager/saleSlice'

export const store = configureStore({
    reducer: {
        navRes: navResReducer,
        auth: authReducer,
        userMan: userManagerReducer,
        detailUser: detailUserReducer,
        sale: salesReducer,
    }
})
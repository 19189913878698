import React from 'react'
// import './login.css'
// import bgLogin from "../../assets/images/bgLogin.png";
// import { MdEmail } from 'react-icons/md';
// import { RiKeyFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import iconUser from "../../assets/icons/icon-feather-user.png";
import iconLock from "../../assets/icons/icon-feather-lock.png";
import { fetchLogin } from '../../store/auth/authThunk';
import { Button, Label, TextInput } from 'flowbite-react';

function Login() {
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => {
    // Notiflix.Loading.pulse();
    console.log(data);
    dispatch(fetchLogin(data))
  }
  return (
    // <div className="container flex h-screen">
    //   <div className="w-5/12 h-full flex items-center p-[30px]">
    //     <img src={bgLogin} className="h-full flex aspect-[700/1020]" alt="" />
    //   </div>

    //   <div className="w-7/12 items-center flex flex-col justify-center items-center">
    //     <span className="text-3xl font-bold text-[#000000] my-[40px]">
    //       Đăng nhập
    //     </span>

    //     <div className="w-6/12">
    //       <form
    //         className="flex flex-col"
    //         onSubmit={handleSubmit(onSubmit)}
    //       >
    //         <label className="w-full flex flex-col">
    //           <span className="text-lg font-semibold my-[5px]">Tài khoản</span>
    //           <div className="h-[60px] flex flex-row items-center border-2 border-solid rounded-md border-[#BCBCBC] ">
    //             <img
    //               src={iconUser}
    //               className="flex max-h-[25px] aspect-[68/76] mx-[10px]"
    //               height="20px"
    //               width="auto"
    //             />
    //             <input
    //               className="w-full flex flex1 outline-0"
    //               {...register("username", {
    //                 required: true,
    //               })}
    //             />
    //           </div>
    //           {errors?.username?.type === "required" && <p className="text-[#FF0000]">Vui lòng nhập tài khoản</p>}
    //         </label>

    //         <label className="w-full flex flex-col mt-[20px]">
    //           <span className="text-lg font-semibold my-[5px]">Mật khẩu</span>
    //           <div className="h-[60px] flex flex-row items-center border-2 border-solid rounded-md border-[#BCBCBC] ">
    //             <img
    //               src={iconLock}
    //               className="flex max-h-[25px] aspect-[68/76] mx-[10px]"
    //               height="20px"
    //               width="auto"
    //             />
    //             <input
    //               type="password"
    //               className="w-full flex flex1 outline-0"
    //               {...register("password", {
    //                 required: true,
    //               })}
    //             />
    //           </div>
    //           {errors?.password?.type === "required" && <p className="text-[#FF0000]">Vui lòng nhập mật khẩu</p>}
    //         </label>

    //         <button
    //           className="w-full h-[60px] text-lg text-[#FFFFFF] rounded-md bg-gradient-to-r from-[#18FFBA] to-[#1C99FF] mt-[10px]"
    //           type="submit"
    //         >
    //           Đăng nhập
    //         </button>
    //       </form>
    //     </div>
    //   </div>
    // </div>
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
      <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
        <h1 className="font-bold text-center text-2xl mb-5">HỆ THỐNG QUẢN TRỊ</h1>
        <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
          <div className="px-5 py-7">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className="font-semibold text-sm text-gray-600 pb-1 block">Tài Khoản</label>
              <input type="text" className="border border-solid border-gray-200 rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" {...register("username", {required: true,})} />
              <label className="font-semibold text-sm text-gray-600 pb-1 block"> Mật Khẩu</label>
              <input type="password" className="border border-solid rounded-lg px-3 border-gray-200 py-2 mt-1 mb-5 text-sm w-full" {...register("password", {required: true,})}/>
              <button type="submit" className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span className="inline-block mr-2">Đăng Nhập</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4 inline-block">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
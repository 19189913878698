import React, { useState } from 'react';
// import { NavWapper } from './Nav.styles';
import { useLocation, useNavigate } from 'react-router-dom'
import { changeIsOpen } from '../../store/navRes/navResSlice';
import { useDispatch, useSelector } from 'react-redux'
import { isOpen } from '../../store/navRes/navResSelector';
import { removeToken } from '../../utils/localStorage'
import { Button, Sidebar } from 'flowbite-react';
import { FaBlackTie, FaDashcube, FaExchangeAlt, FaHammer, FaMoneyCheckAlt, FaUniversalAccess, FaUserAlt } from 'react-icons/fa';

function Nav() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const isopen = useSelector(isOpen)
    // const Nav = isopen ? NavWapper.Open : NavWapper;
    // handle logout
    const handleLogOut = () => {
        removeToken()
        navigate('/')
        window.location.reload();
    }

    const admin = useSelector((state) => state.auth.user);

    return (
        <Sidebar aria-label="Sidebar with logo branding">
            <Sidebar.Logo
                href="#"
                img="https://img.onebidd.com/logo.png"
                imgAlt="Flowbite logo"
                className='flex justify-center mb-0'
            >
            </Sidebar.Logo>
            <Sidebar.Items>
                <Sidebar.ItemGroup>
                    <Sidebar.Item
                        href="#"
                        icon={FaDashcube}
                    >
                        Thống Kê
                    </Sidebar.Item>
                    <Sidebar.Item
                        onClick={() => {
                            navigate('/user')
                            dispatch(changeIsOpen(false))
                        }}
                        icon={FaUserAlt}
                    >

                        Người dùng

                    </Sidebar.Item>
                    { admin && admin.role === 'ADMIN' ?
                    <>
                    <Sidebar.Item
                        onClick={() => {
                            navigate('/account')
                            dispatch(changeIsOpen(false))
                        }}
                        icon={FaUniversalAccess}
                    >
                        Tài khoản
                    </Sidebar.Item>
                    <Sidebar.Item
                        onClick={() => {
                            navigate('/sessionman')
                            dispatch(changeIsOpen(false))
                        }}
                        icon={FaExchangeAlt}
                    >
                        Phiên
                    </Sidebar.Item>
                    <Sidebar.Item
                        onClick={() => {
                            navigate('/setting')
                            dispatch(changeIsOpen(false))
                        }}
                        icon={FaHammer}
                    >
                        Cài đặt
                    </Sidebar.Item>
                    <Sidebar.Item
                        onClick={() => {
                            navigate('/DepositManager')
                            dispatch(changeIsOpen(false))
                        }}
                        icon={FaMoneyCheckAlt}
                    >
                        Nạp/Rút
                    </Sidebar.Item>
                    <Sidebar.Item
                        onClick={() => {
                            navigate('/sale')
                            dispatch(changeIsOpen(false))
                        }}
                        icon={FaBlackTie}
                    >
                        Sale
                    </Sidebar.Item> 
                    </>    : ""}
                </Sidebar.ItemGroup>
            </Sidebar.Items>
        </Sidebar>
    )
}

export default Nav
import React, { useState, useEffect } from 'react'
// import { User } from './UserMan.styles'
// import searchicon from '../../../assets/images/searchicon.png'
import { AiOutlineLeft } from 'react-icons/ai'
import { AiOutlineRight } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { getListUser, lockeduser } from '../../../services/userManServices'
import { useDispatch, useSelector } from 'react-redux'
import { ChangePage, SearchUser } from '../../../store/manager/usermanagerSlice'
import { selecUser } from '../../../store/detailUser/detailUserSlice'
import Notiflix from 'notiflix'
import Header from '../../../components/header/Header'
import { Button, Pagination, Table, TextInput } from 'flowbite-react'
import { User } from './UserMan.styles'

function UserMan() {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [value, setValue] = useState('');
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // handle view detail
    const handleViewDetail = (email) => {
        dispatch(selecUser(email))
        navigate('/detailUser')
    }
    // get list user
    const params = useSelector(state => state.userMan)
    const handleGetuser = () => {
        getListUser(params)
            .then(res => {
                setUsers(res.data);
                setPageCount(res.data.meta.pageCount);
                setPage(res.data.meta.page);
                Notiflix.Loading.remove();
            })
            .catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        handleGetuser()
    }, [page, params])

    const handleChangePage = (e) => {
        dispatch(ChangePage(e))
        setPage(e)
    }
    // handle search
    const handleSearch = (e) => {
        setValue(e.target.value)
    }

    const handleBtnSearch = () => {
        Notiflix.Loading.pulse();
        dispatch(SearchUser(value))
        setPage(1)
    }
    // handle lock user
    const handleLock = ({ value, email }) => {
        lockeduser({ email: email, isLocked: value })
            .then(() => {
                getListUser(params)
                    .then(res => {
                        setUsers(res.data);
                        Notiflix.Loading.remove();
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
    }
    const admin = useSelector((state) => state.auth.user);

    return (
        <div>
            <div className='my-5 mt-2 top-0 my-0 left-2/4 absolute flex'>
                <TextInput
                    id="small"
                    type="text"
                    className='w-[200px]'
                    placeholder='Tìm kiếm'
                    onChange={(e) => handleSearch(e)}
                />
                <Button className='ml-2' onClick={handleBtnSearch}>Tìm</Button>
            </div>
            <Header />
            <Table hoverable striped className='drop-shadow-none'>
                <Table.Head>
                    <Table.HeadCell>
                        ID
                    </Table.HeadCell>
                    <Table.HeadCell>
                        HỌ VÀ TÊN
                    </Table.HeadCell>
                    <Table.HeadCell>
                        EMAIL
                    </Table.HeadCell>
                    <Table.HeadCell>
                        SỐ ĐIỆN THOẠI
                    </Table.HeadCell>
                    <Table.HeadCell>
                        ĐẠI LÝ
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <span className="sr-only">
                            Sửa
                        </span>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {users.data?.map((item, index) => (
                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell>
                                {item.accountId}
                            </Table.Cell>
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {item.fullName}
                            </Table.Cell>
                            <Table.Cell>
                                {item.email}
                            </Table.Cell>
                            <Table.Cell>
                                {item.phone}
                            </Table.Cell>
                            <Table.Cell>
                                {admin.username === "adminfx" ? item.manager.username : admin.username}
                            </Table.Cell>
                            <Table.Cell>
                                <button
                                    onClick={() => handleViewDetail(item.email)}
                                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                >
                                    <p>
                                        Xem Chi Tiết
                                    </p>
                                </button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination className="flex mt-5 items-center justify-center text-center flex-col"
                currentPage={page}
                onPageChange={handleChangePage}
                showIcons
                totalPages={pageCount}
            />
        </div>

    )
}

export default UserMan